const colors = {
  example1: {
    primary: '#99424f',
    secondary: '#fff',
    accent: '#333',
    light: '#636466'
  },
  RedBased: {
    primary: '#ffffff',
    secondary: '#be1e2d',
    accent: '#333132',
    light: '#636466'
  },
  BlueBased: {
    primary: '#fff', //white
    secondary: '#009be5',
    accent: '#333', //black gray
    light: '#636466'
  }
};

export default colors.RedBased;
