import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core'
import { fontSize, minHeight } from 'styled-system';
import PropTypes from 'prop-types';
import Navigation from '../Navigation';
import Footer from '../Footer';
import colors from '../../utils/colors';
//import 'semantic-ui-css/semantic.min.css'
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
//import 'devextreme/dist/css/dx.softblue.css';
import '../../styles/dxoverride.css';
import CookieConsent from "react-cookie-consent";

/*
import { currentTheme, refreshTheme, registerTheme } from "devextreme/viz/themes";
 
var customTheme = {
  name: 'mySVGThemeForFont',
  chart: {

    legend: { font: { family: 'Saira Condensed' } },  
    title: { font: { family: 'Saira Condensed' } },  
    tooltip: { font: { family: 'Saira Condensed' } }      
  },
  pie: { 

    legend: { font: { family: 'Saira Condensed' } },  
    title: { font: { family: 'Saira Condensed' } },  
    tooltip: { font: { family: 'Saira Condensed' } }          
  },

};
registerTheme(customTheme, "generic.light");
currentTheme("generic.dark");
refreshTheme();
*/
/* eslint-enable */

const FontConfig = styled.div`
  ${fontSize} ${minHeight};
`;

const Layout = ({ children }) => (
  <>
  <Global styles={css`
  * {
      font-family: "Saira Condensed";
      box-sizing: border-box;
  };

  a {
    text-decoration: none;
    color: inherit;
  };
  a:hover {
    cursor: pointer;
  };
  h1, h2, h3, h4, h5, h6 {
    font-family: "Rationale";
    color: ${colors.secondary}
  };
  p {
    color: ${colors.accent}
  };
  iframe {
    border: none;
  };
`} />
  <FontConfig fontSize={[1, 2, 3]} minHeight={'500px'}>
    <Navigation />
    {children}
    <Footer />
  </FontConfig>

  <CookieConsent debug={false} buttonText={"Accept"} style={{  background: "black" }}> 
    This website uses cookies to enhance the user experience. Please tap [Accept] to confirm you accept it.
</CookieConsent>  
  </>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired
};

export default Layout;
