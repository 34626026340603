import styled from '@emotion/styled';
import {
  space,
  width,
  height,
  minHeight,
  maxHeight,
  minWidth,
  maxWidth,
  fontSize,
  color,
  textAlign,
  justifyContent,
  style
} from 'styled-system';

const wrap = style({
  prop: 'wrap',
  cssProperty: 'flexWrap'
});

export const Box = styled.div`
  overflow: hidden;
  ${space} ${width} ${fontSize} ${color} ${textAlign} ${height} ${minHeight} ${maxHeight} ${minWidth} ${maxWidth};
`;

export const Flex = styled.div`
  display: flex;
  ${justifyContent} ${wrap};
`;
